<template>
  <section class="filter">
    <div class="filter__top">
      <div class="filter__top-column--first">
        <div
          v-for="(field, index) in quickFilter"
          :key="index"
          class="filter__top-field"
        >
          <component
            v-model="values[field.name]"
            v-bind="{
              options: field.values
                ? field.values.map((item) => ({
                    ...item,
                    value: item.id,
                  }))
                : [],
              field: field,
              ...getFieldAttributes(field),
            }"
            :is="fieldTypes[field.filterType].component"
            :label="field.label"
            @fetchItems="onFetchItems"
            @updateFilter="onUpdateFilter"
            @clearChildList="onClearChildList"
          />
        </div>
      </div>

      <div v-if="advancedFilter.length" class="filter__top-column--secont">
        <button
          class="filter__button-toggle"
          @click="showAdvancedFilter = !showAdvancedFilter"
        >
          <sm-icon name="search" class="filter__search-icon" />
          Расширенный поиск
        </button>
      </div>
    </div>

    <ul v-show="filterBadges.length" class="filter__badge-list">
      <li
        class="filter__badge"
        v-for="(badge, index) in filterBadges"
        :key="index"
      >
        <span class="filter__badge-text">
          {{ `${badge.name} ${badge.operation} "${badge.value}"` }}
        </span>
        <button
          class="filter__button-remove-badge"
          @click="onRemoveBadge(badge.key)"
        >
          <sm-icon name="times" class="filter__remove-badge-icon" />
        </button>
      </li>
    </ul>

    <div
      v-if="advancedFilter.length && showAdvancedFilter"
      class="filter__bottom"
    >
      <div class="filter__bottom-row">
        <div
          v-for="(field, index) in advancedFilter"
          :key="index"
          class="filter__bottom-field"
        >
          <p class="filter__bottom-field-description">{{ field.label }}</p>
          <component
            v-model="values[field.name]"
            v-bind="{
              options: field.values
                ? field.values.map((item) => ({
                    ...item,
                    value: item.id,
                  }))
                : [],
              field: field,
              ...getFieldAttributes(field),
            }"
            :is="fieldTypes[field.filterType].component"
            @fetchItems="onFetchItems"
            @updateFilter="onUpdateFilter"
            @clearChildList="onClearChildList"
          />
        </div>
      </div>

      <div class="filter__bottom-panel">
        <button
          class="filter__button-close"
          @click="showAdvancedFilter = false"
        >
          Закрыть поиск
        </button>
      </div>
    </div>

    <div class="filter__button-group">
      <sm-button
        class="filter__button"
        :isLoading="isLoadingApplyButton"
        @click="onSearch"
        >Применить фильтр</sm-button
      >
      <sm-button class="filter__button" neutrall outline @click="onReset"
        >Сбросить</sm-button
      >
    </div>
  </section>
</template>

<script>
// component
import SmIcon from '@/components/common/SmIcon.vue';
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmInputWithSelect from '@/components/common/forms/SmInputWithSelect.vue';
import SmDatepickerWithSelect from '@/components/common/forms/SmDatepickerWithSelect.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmSelectWithSearch from '@/components/common/forms/SmSelectWithSearch.vue';

export default {
  name: 'SmEditableListFilter',

  components: {
    SmIcon,
    SmSelect,
    SmInputWithSelect,
    SmDatepickerWithSelect,
    SmButton,
    SmSelectWithSearch,
  },

  model: {
    prop: 'values',
  },

  props: {
    values: {
      type: Object,
      default: () => ({}),
    },

    fields: {
      type: Array,
      required: true,
    },

    quickFilterFieldsNumber: {
      type: Number,
      default: 2,
    },

    filterBadges: {
      type: Array,
      default: () => [],
    },

    isLoadingApplyButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fieldTypes: {
        String: {
          component: 'sm-input-with-select',
          attributes: {
            type: 'text',
            filterType: 'String',
          },
        },

        Int: {
          component: 'sm-input-with-select',
          attributes: {
            type: 'number',
            filterType: 'Int',
          },
        },

        Decimal: {
          component: 'sm-input-with-select',
          attributes: {
            type: 'number',
            filterType: 'Decimal',
          },
        },

        Date: {
          component: 'sm-datepicker-with-select',
          attributes: {
            type: 'date',
            filterType: 'Date',
          },
        },

        DateTime: {
          component: 'sm-datepicker-with-select',
          attributes: {
            type: 'datetime',
            filterType: 'DateTime',
            format: 'DD.MM.YYYY HH:mm:ss',
            valueType: 'DD.MM.YYYY HH:mm:ss',
            timeTitleFormat: 'DD.MM.YYYY HH:mm:ss',
          },
        },

        Bool: {
          component: 'sm-select',
          attributes: {
            options: [
              { name: 'Все', value: '' },
              { name: 'Да', value: true },
              { name: 'Нет', value: false },
            ],
          },
        },

        FromCatalog: {
          component: 'sm-select-with-search',
          attributes: {
            clearable: true,
          },
        },
      },
      showAdvancedFilter: false,
    };
  },

  computed: {
    quickFilter() {
      return this.fields.slice(0, this.quickFilterFieldsNumber);
    },

    advancedFilter() {
      return this.fields.slice(this.quickFilterFieldsNumber);
    },
  },

  methods: {
    onRemoveBadge(key) {
      this.$emit('removeBadge', key);
    },

    onSearch() {
      this.$emit('search');
    },

    onReset() {
      this.$emit('reset');
    },

    onFetchItems(items) {
      this.$emit('fetchItems', items);
    },

    onUpdateFilter(field) {
      this.$emit('updateFilter', field);
    },

    onClearChildList(field) {
      this.$emit('clearChildList', field);
    },

    getFieldAttributes(field) {
      const customAttributes = field.attributes || {};

      return {
        ...this.fieldTypes[field.filterType].attributes,
        ...customAttributes,
      }
    },
  },
};
</script>

<style lang="scss">
.filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.filter__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 15px;
  border-bottom: 1px solid var(--gray);
}

.filter__top-column {
  display: flex;
}

.filter__top-column--first {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 75%;
  margin: 0 -15px;
}

.filter__top-column--second {
  justify-content: flex-end;
  flex: 0 0 25%;
}

.filter__top-field {
  display: flex;
  padding: 0 15px;
  margin-bottom: 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.filter__button-toggle {
  display: flex;
  align-items: center;

  line-height: 1;
  white-space: nowrap;

  background: none;
  border: none;

  cursor: pointer;
}

.filter__search-icon {
  width: 28px;
  height: 28px;

  color: var(--gray);
}

.filter__badge-list {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.filter__badge {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;

  line-height: 1;
  color: var(--white);

  background-color: rgba(var(--rgb-blue), 0.6);
  border-radius: 10px;

  &:last-of-type {
    margin-right: 0;
  }
}

.filter__badge-text {
  margin-right: 10px;
}

.filter__button-remove-badge {
  font-size: 0;
  color: var(--red);

  border: none;
  background: none;
}

.filter__remove-badge-icon {
  width: 18px;
  height: 18px;
}

.filter__bottom {
  margin: 10px 15px;
}

.filter__bottom-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.filter__bottom-field {
  display: flex;
  flex: 0 0 50%;
  padding: 0 15px;
  margin-bottom: 15px;
}

@media only screen and (width: var(--breakpoint-sm-max)) {
  .filter__bottom-field {
    flex: 0 0 100%;
  }
}

.filter__bottom-field-description {
  margin: 10px 10px 0 0;
  width: 250px;

  font-size: 14px;
  line-height: 1;

  @media only screen and (min-width: var(--breakpoint-sm-max)) {
    width: 150px;
  }
}

.filter__bottom-panel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.filter__button-close {
  font-size: 14px;
  color: var(--gray);

  background: none;
  border: none;

  cursor: pointer;
}

.filter__button-group {
  display: flex;
  // flex-wrap: wrap;
  padding: 0 15px;
}

.filter__button {
  width: 180px;

  &:first-of-type {
    margin-right: 15px;
  }
}
</style>
